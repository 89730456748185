import { h } from 'preact';

import SVG from '../common/SVG';
import TextComponents from '../common/textComponents';
import Intl from '../common/intl';
import Modal from '../common/modal';

const BaseErrorModal = ({ isActive, onClose, children }) => (
  <Modal onClose={onClose} isActive={isActive}>
    <SVG src="/assets/svg/icon-error.svg" pageImage />
    <TextComponents element="h2" center>
      <Intl textKey="I18N_BUMMER">Oh no!</Intl>
    </TextComponents>
    <br />
    <div>{children}</div>
  </Modal>
);

export default BaseErrorModal;
