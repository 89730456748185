import { h } from 'preact';
import classNames from 'classnames';

import style from './style.scss';

const ToggleButton = ({ active, onClick }) => (
  // eslint-disable-next-line
  <div className={style.toggleButtonContainer} onClick={onClick}>
    <input type="checkbox" checked={active} />
    <div className={classNames(style.toggleButton, { [style.active]: active })}>
      {active && (<span className={style.yes}>Yes</span>)}
      <i />
      {!active && (<span className={style.no}>No</span>)}
    </div>
  </div>
);
export default ToggleButton;
