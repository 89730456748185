import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';

import { togglePrivacyPolicy, toggleTermsOfService } from './actions';

import CheckboxInput from './checkboxInput';
import {
  getTermsLink,
  getPrivacyLink,
  TermsLabel,
  PrivacyLabel,
} from '../labels';


const TcToggles = (props) => {
  const {
    tosToggled,
    privacyToggled,
    toggleTermsOfServiceAction,
    togglePrivacyPolicyAction,
  } = props;

  return (
    <Fragment>
      <CheckboxInput
        onChangeHandler={togglePrivacyPolicyAction}
        textLink={getPrivacyLink()}
        textLabel={<PrivacyLabel />}
        checkboxValue={privacyToggled}
      />
      <hr />
      <CheckboxInput
        onChangeHandler={toggleTermsOfServiceAction}
        textLink={getTermsLink()}
        textLabel={<TermsLabel />}
        checkboxValue={tosToggled}
      />
    </Fragment>
  );
};

export default connect(
  ({
    termsAndConditions: { privacyToggled, tosToggled } = {},
  }) => ({
    privacyToggled,
    tosToggled,
  }),
  () => ({
    togglePrivacyPolicyAction: togglePrivacyPolicy,
    toggleTermsOfServiceAction: toggleTermsOfService,
  }),
)(TcToggles);
