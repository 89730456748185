
import { h } from 'preact';

import style from './style.scss';
import NBSP from '../../../../../common/nbsp';
import Intl from '../../../../../common/intl';
import ToggleButton from '../../../../../common/toggleButton';

const CheckboxInput = (props) => {
  const {
    textLabel,
    textLink,
    checkboxValue,
    onChangeHandler,
  } = props;

  return (
    <div className={style.container}>
      {/* eslint-disable-next-line */}
      <label className={style.label}>
        <Intl textKey="I18N_TERMS_MODAL_I_ACCEPT">I accept the</Intl>
        <NBSP />
        <a
          href={textLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {textLabel}
        </a>
      </label>
      <div className={style.toggleButtonWrapper}>
        <ToggleButton active={checkboxValue} onClick={() => onChangeHandler(!checkboxValue)} />
      </div>
    </div>
  );
};


export default CheckboxInput;
