import { h } from 'preact';
import { connect } from 'redux-zero/react';
import { memo, lazy, Suspense } from 'preact/compat';

import TcParagraph from './tcParagraph';
import LoaderWrapper from './tcToggles/loaderWrapper';

const tcTogglesPromise = import(/* webpackChunkName: "tcToggles" */'./tcToggles');
const LazyToggles = lazy(() => tcTogglesPromise);

const TcAcceptanceBlock = ({ tcTogglesEnabled }) => {
  if (tcTogglesEnabled) {
    return (
      <Suspense fallback={<LoaderWrapper />}>
        <LazyToggles />
      </Suspense>
    );
  }

  return <TcParagraph />;
};

export default connect(
  ({ event: { tcTogglesEnabled } = {} }) => ({ tcTogglesEnabled }),
)(memo(TcAcceptanceBlock));
