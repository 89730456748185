import { h, cloneElement } from 'preact';
import { connect } from 'redux-zero/preact';

import { logError } from '../../helpers/apis/logger';

import Loader from '../common/loader';
import EventNotLiveErrorModal from '../errorModals/eventNotLive';

const EventGuard = ({ children, ...props }) => {
  const {
    event: {
      eventStatus,
    } = {},
  } = props;
  if (!eventStatus) {
    return <Loader fullScreen />;
  }

  if (eventStatus !== 'live') {
    logError({ message: 'Event is not live' });
    return <EventNotLiveErrorModal show />;
  }

  if (children instanceof Array) {
    return children.map(child => cloneElement(child, props));
  }

  return cloneElement(children, props);
};

export default connect(
  ({ event }) => ({ event }),
)(EventGuard);
