import { h } from 'preact';
import Loader from '../../../../common/loader';

import style from './style.scss';

const LoaderWrapper = () => (
  <div className={style.loaderWrapper}>
    <Loader />
  </div>
);

export default LoaderWrapper;
