/* eslint-disable react/no-danger */
import { h } from 'preact';

import NBSP from '../../../../common/nbsp';
import Intl, { translate } from '../../../../common/intl';

import {
  getTermsLink,
  getPrivacyLink,
  TermsLabel,
  PrivacyLabel,
} from '../labels';

import style from './style.scss';

const TcParagraph = () => (
  <div className={style.container}>
    <p>
      <span dangerouslySetInnerHTML={{ __html: translate('I18N_TERMS_MODAL_BY_PROCEEDING', 'By proceeding, you accept the') }} />
      <NBSP />
      <a
        href={getPrivacyLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PrivacyLabel />
      </a>
      <NBSP />
      <Intl textKey="I18N_TERMS_MODAL_AND_THE">and the</Intl>
      <NBSP />
      <a
        href={getTermsLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TermsLabel />
      </a>
    </p>
  </div>
);

export default TcParagraph;
