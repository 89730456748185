import { h } from 'preact';

import SVG from '../../../components/common/SVG';
import Intl from '../../../components/common/intl';
import TextComponent from '../../../components/common/textComponents';

import style from './slides.scss';

const StadiumSlide = ({ onImgLoad }) => (
  <div className={style.slide}>
    <div className={style.imageContainer}>
      <SVG src="/assets/svg/onboard-stadium.svg" onLoad={onImgLoad} />
    </div>
    <div className={style.textContainer}>
      <TextComponent element="h3">
        <Intl textKey="I18N_ONBOARD_PAGE1_TITLE">
          Get ready for your picture!
        </Intl>
      </TextComponent>
      <TextComponent element="p">
        <Intl textKey="I18N_ONBOARD_PAGE1_DESCRIPTION">
          Tell us where you&apos;re sitting and our cameras will find you.
        </Intl>
      </TextComponent>
    </div>
  </div>
);

export default StadiumSlide;
