import { h } from 'preact';

import { route } from '../../helpers/router';

import NBSP from '../common/nbsp';
import TextComponents from '../common/textComponents';
import Intl from '../common/intl';
import Button from '../common/button';
import BaseErrorModal from './baseErrorModal';


const EventNotLive = ({ show }) => {
  if (!show) {
    return null;
  }

  const onYesClick = () => route('/album');
  const onNoClick = () => route('/');

  return (
    <BaseErrorModal isActive>
      <TextComponents element="p" center>
        <Intl textKey="I18N_SOMETHING_WENT_WRONG">Sorry, our cameras are now asleep, but you can still view and share your photos.</Intl>
        <NBSP />
        <Intl textKey="I18N_YOU_CAN_SEE_PREVIOUS_SNAPSHOTS">You can still see your previous pictures</Intl>
      </TextComponents>
      <br />
      <Button primary onClick={onYesClick}>
        <Intl textKey="I18N_BACK_TO_ALBUM_BUTTON">View your photos</Intl>
      </Button>
      <Button primary onClick={onNoClick}>
        <Intl textKey="I18N_CANCEL_BUTTON">Nope</Intl>
      </Button>
    </BaseErrorModal>
  );
};

export default EventNotLive;
