/* eslint-disable react/no-danger */
import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';
import { combineActions } from 'redux-zero/utils';

import { route } from '../../helpers/router';
import { logGAGoal, gaGoals } from '../../helpers/ga';
import { mixpanelTrack, mixpanelEvents } from '../../helpers/mixpanel';
import { setTermsAcceptance } from './actions';
import { setOnboardingDisplayed } from '../../routes/onboarding/actions';

import Modal from '../common/modal';
import Button from '../common/button';
import Intl, { translate } from '../common/intl';
import TcAcceptanceBlock from './components/tcAcceptanceBlock';

import style from './style.scss';

const { TERMS } = gaGoals;

const touchMoveHandler = evt => evt.stopPropagation();

const TermsModal = (props) => {
  const {
    tosToggled,
    privacyToggled,
    tcTogglesEnabled,
    geolocationEnabled,
    geofenceEnabled,
    onClose,
    setTermsAcceptanceHandler,
    isTicketMode,
  } = props;

  const modalHeaderText = <Intl textKey="I18N_TERMS_MODAL_TITLE">Terms and conditions</Intl>;

  const acceptBtnHandler = () => {
    setTermsAcceptanceHandler(true);
    logGAGoal(TERMS);
    mixpanelTrack(mixpanelEvents.TERMS);

    if (geofenceEnabled && !geolocationEnabled) {
      route('/location', true);
    } else if (isTicketMode) {
      route('/findme/confirm', true);
    } else {
      route('/findme', true);
    }
  };

  const acceptBtnDisabled = tcTogglesEnabled && (!privacyToggled || !tosToggled);

  const footer = (
    <Fragment>
      <hr />
      <TcAcceptanceBlock />
      <hr />
      <div className={style.separator} />
      <Button primary next disabled={acceptBtnDisabled} onClick={() => acceptBtnHandler()}>
        <Intl textKey="I18N_ACCEPT_BUTTON">Accept</Intl>
      </Button>
    </Fragment>
  );

  return (
    <Modal
      isActive
      header={modalHeaderText}
      footer={footer}
      onClose={onClose}
      headerShadow
    >
      <div className={style.termsBody} onTouchMove={touchMoveHandler}>
        <h4
          className="textContainer"
          dangerouslySetInnerHTML={{
            __html: translate(
              'I18N_TERMS_MODAL_TEXT',
              `We know there&apos;s a game going on,
              but take a moment to review our terms and conditions`,
            ),
          }}
        />

        <p
          className="textContainer"
          dangerouslySetInnerHTML={{
            __html: translate(
              'I18N_TERMS_MODAL_DESCRIPTION',
              'Terms description',
            ),
          }}
        />
      </div>
    </Modal>
  );
};

export default connect(
  ({
    geolocationEnabled,
    event: { geofence, tcTogglesEnabled } = {},
    termsAndConditions: { privacyToggled, tosToggled } = {},
    isTicketMode,
  }) => ({
    tosToggled,
    privacyToggled,
    tcTogglesEnabled,
    geolocationEnabled,
    geofenceEnabled: geofence?.enabled,
    isTicketMode,
  }),
  () => ({
    setTermsAcceptanceHandler: combineActions(setTermsAcceptance, setOnboardingDisplayed),
  }),
)(TermsModal);
