import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useRef, useState, useEffect } from 'preact/hooks';
import Match from 'preact-router/match';

import TermsModal from '../../components/termsModal';
import Header from '../../components/header';
import Button from '../../components/common/button';
import Intl from '../../components/common/intl';
import EventGuard from '../../components/eventGuard';
import Dots from './components/reactCarouselDots';
import StadiumSlide from './components/stadiumSlide';
import PrivacySlide from './components/privacySlide';

import { route } from '../../helpers/router';
import { sendLog } from '../../helpers/apis/logger';
import { isPreviewMode } from '../../helpers/preview';
import { setTempSrs } from '../findme/srs/actions';

import style from './style.scss';

let timeout;

const handleTouchmove = e => e.preventDefault();

const Onboarding = ({
  srs,
  isTermsAccepted,
  geolocationEnabled,
  geofence,
  ticketId,
  setSeatType,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shouldAutoplay, setShouldAutoplay] = useState(true);
  const carouselRef = useRef();

  const onClickHandler = () => {
    if (!isTermsAccepted) {
      route('/terms');
    } else if (geofence.enabled && !geolocationEnabled) {
      route('/location');
    } else {
      route('/findme');
    }
  };

  const logSlideChange = (value, type) => {
    const context = {
      type: 'slideChange',
      toSlide: value,
      transitionType: type,
    };
    sendLog('info', context);
  };

  const onChangeHandler = (value) => {
    logSlideChange(value, 'triggered');
    setCurrentSlide(value);
    if (shouldAutoplay) {
      clearTimeout(timeout);
      setShouldAutoplay(false);
    }
  };

  useEffect(() => {
    // this logic will skip onboarding and redirect to terms if ticket info exists
    if (!isTermsAccepted && ticketId && srs.section && srs.row && srs.seat && srs.type) {
      if (['box', 'suite'].includes(srs.type) && srs.row === '1' && srs.seat === '1') {
        const skipped = { row: true, seat: true };
        setSeatType({}, skipped);
      }

      route('/terms');
    }
  }, [ticketId]);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouchmove, { passive: false });
    return () => {
      window.removeEventListener('touchmove', handleTouchmove, { passive: false });
    };
  }, [carouselRef]);

  const autoplayHandler = () => {
    if (shouldAutoplay) {
      timeout = setTimeout(() => {
        setCurrentSlide(1);
        logSlideChange(1, 'auto');
        setShouldAutoplay(false);
      }, 5000);
    }
  };

  return (
    <Fragment>
      <Header />
      <div className={style.page}>
        <div className={style.sliderContainer}>
          <Carousel
            ref={carouselRef}
            centered
            onChange={value => onChangeHandler(value)}
            value={currentSlide}
            keepDirectionWhenDragging
            animationSpeed={800}
            className={style.slider}
          >
            <StadiumSlide onImgLoad={autoplayHandler} />
            <PrivacySlide />
          </Carousel>
          <Dots
            number={2}
            value={currentSlide}
            onChange={value => onChangeHandler(value)}
          />
        </div>
        <Button primary next onClick={onClickHandler}>
          <Intl textKey="I18N_NEXT_BUTTON">
            Next
          </Intl>
        </Button>
      </div>
      <Match path="/terms">
        {({ matches }) => matches && <TermsModal onClose={() => window.history.back()} />}
      </Match>
    </Fragment>
  );
};

const ConnectedComponent = connect(({
  srs,
  event: { geofence },
  termsAccepted,
  termsModalOpen,
  geolocationEnabled,
  ticketInfo: { ticketId } = {},
}) => ({
  srs,
  geofence,
  isTermsAccepted: termsAccepted,
  isTermsModalOpen: termsModalOpen,
  geolocationEnabled,
  ticketId,
}), () => ({ setSeatType: setTempSrs }))(Onboarding);

export default (props) => {
  if (isPreviewMode()) {
    return <ConnectedComponent {...props} />;
  }

  return (
    <EventGuard>
      <ConnectedComponent {...props} />
    </EventGuard>
  );
};
