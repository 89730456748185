import { h } from 'preact';

import SVG from '../../../components/common/SVG';
import Intl from '../../../components/common/intl';
import TextComponent from '../../../components/common/textComponents';

import style from './slides.scss';

const PrivacySlide = () => (
  <div className={style.slide}>
    <div className={style.imageContainer}>
      <SVG src="/assets/svg/shield.svg" />
    </div>
    <div className={style.textContainer}>
      <TextComponent element="h3">
        <Intl textKey="I18N_ONBOARD_PAGE2_TITLE">
          Your photos stay 100% private
        </Intl>
      </TextComponent>
      <TextComponent element="p">
        <Intl textKey="I18N_ONBOARD_PAGE2_DESCRIPTION">
          Unless you decide they&apos;re awesome enough to share
        </Intl>
      </TextComponent>
    </div>
  </div>
);

export default PrivacySlide;
