import { h } from 'preact';
import classNames from 'classnames';

import style from './style.scss';

const ReactCarouselDots = ({ number, value, onChange: onChangeParentHandler }) => {
  const dots = [];

  const onChange = index => () => onChangeParentHandler(index);

  for (let i = 0; i < number; i++) {
    const active = i === value;

    const dot = (
      <li key={i}>
        {/* eslint-disable-next-line */}
        <div className={style.dot} type="button" onClick={onChange(i)}>
          <span className={classNames(style.dotInner, { [style.active]: active })}>
            {i + 1}
          </span>
        </div>
      </li>
    );

    dots.push(dot);
  }

  return (
    <ul className="BrainhubCarousel__dots">{dots}</ul>
  );
};

export default ReactCarouselDots;
